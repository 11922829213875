<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSave('MailBoxesRules')"
    @cancel="onCancel('MailBoxesRules')"
  />
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'MailBoxesRulesCreate',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'MailBoxesRules',
      pageHeader: 'Создание правил для почтовых ящиков',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Правила для почтовых ящиков',
          route: { name: 'MailBoxesRules' },
        },
        {
          text: 'Создание правил для почтовых ящиков',
        },
      ],
      initialForm: {
        name: null,
        fromMail: null,
        mailBoxId: null,
        mailBoxGroupId: null,
        textInSubject: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      emails: (state) => state.common.emails.data,
      emailGroups: (state) => state.common.emailGroups.data,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название правила',
            },
            {
              type: 'text',
              key: 'fromMail',
              label: 'Email адрес отправителя',
            },
            {
              type: 'select',
              key: 'mailBoxId',
              label: 'Почтовый ящик',
              list: this.emails
            },
            {
              type: 'select',
              key: 'mailBoxGroupId',
              label: 'Группа сообщений почтового ящика',
              list: this.emailGroups
            },
            {
              type: 'textarea',
              key: 'textInSubject',
              label: 'Текст в теме письма',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const emails = this.getCommonList({ name: 'Emails' });

    Promise.all([
      emails,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  
  watch: {
  'form.mailBoxId': {
    handler(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getCommonList({
          name: 'EmailGroups',
          params: { mailboxId: this.form.mailBoxId },
        });
      }
    },
    immediate: true,
  }},

  methods: {
    ...mapActions({
      createItem: 'editableList/createItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
